import { useState, useEffect } from 'react';

export const useTelegramVerification = (tg, marzbanUser, fetchMarzbanUser) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [verificationFailed, setVerificationFailed] = useState(false);

  const verifyTelegramData = async (initData) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/verify-telegram-data`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ initData }),
      });

      const result = await response.json();
      if (response.ok) {
        sessionStorage.setItem('userData', JSON.stringify(result.userData));
        setUserData(result.userData);
        return result.userData.id;
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      setVerificationFailed(true);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (tg && tg.initData) {
        try {
          const userId = await verifyTelegramData(tg.initData);
          if (userId && !marzbanUser) {
            await fetchMarzbanUser(userId);
          }
        } catch (error) {
          setVerificationFailed(true);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        setVerificationFailed(true);
      }
    };

    fetchData();
  }, [tg, marzbanUser, fetchMarzbanUser]);

  return { userData, loading, verificationFailed };
};
