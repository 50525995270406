// /components/ProfileSection.js
import React from 'react';

function ProfileSection({ profilePictureUrl, userData, handleNavigateToBot, handleNavigateToChannel, handleNavigateToSupport }) {
  return (
    <div className='logo-wrapper'>
      <img className='logo' src={profilePictureUrl || '/logo.jpg'} alt='User Pic' />
      <div>
        <div className='name text'>{userData?.firstname || userData?.username || 'Пользователь'}</div>
        <div className='tg-id text'>ID: {userData?.tgid}</div>

            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px'}}>
                <button
                    style={{ border: 'none', background: 'none', margin: '0', padding: '0', cursor: 'pointer' }}
                    onClick={handleNavigateToBot}
                >
                    <div style={{ textAlign: 'left' }}>
                        <span className='text' style={{ textDecoration: 'underline' }}>Telegram-бот</span>
                    </div>
                </button>
                <button
                    style={{ border: 'none', background: 'none', margin: '0', padding: '0', cursor: 'pointer' }}
                    onClick={handleNavigateToChannel}
                >
                    <div style={{ textAlign: 'left' }}>
                        <span className='text' style={{ textDecoration: 'underline' }}>Telegram-канал</span>
                    </div>
                </button>
                <button
                    style={{ border: 'none', background: 'none', margin: '0', padding: '0', cursor: 'pointer' }}
                    onClick={handleNavigateToSupport}
                >
                    <div style={{ textAlign: 'left' }}>
                        <span className='text' style={{ textDecoration: 'underline' }}>Поддержка</span>
                    </div>
                </button>
            </div>
      </div>
    </div>
  );
}

export default ProfileSection;
