import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Offer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const storedUserData = sessionStorage.getItem('userData');
  const params = new URLSearchParams(location.search);
  const userId = params.get('userId');

  useEffect(() => {
    if (storedUserData) {
      const tg = window.Telegram.WebApp;

      const handleNavigateHome = () => {
        navigate('/tariffs?userId=' + userId);
      };

      // Set up MainButton and show it
      tg.MainButton.setText("Назад").show().onClick(handleNavigateHome);

      // Clean up the MainButton on component unmount
      return () => {
        tg.MainButton.offClick(handleNavigateHome).hide();
      };
    }
  }, [navigate, storedUserData]); // Ensure `navigate` and `storedUserData` are dependencies

  return (
    <div className="wrapper">
      <h1>ПУБЛИЧНАЯ ОФЕРТА</h1>
      <h2>о заключении договора об оказании услуг</h2>
      <h3>Общие положения</h3>
        <p>
          Настоящая Публичная оферта содержит условия заключения Договора об оказании услуг (далее — «Договор об
          оказании услуг» и/или «Договор»). Настоящая оферта представляет собой предложение, адресованное одному или
          нескольким конкретным лицам, достаточно определённое и выражающее намерение ООО «PVT KEY», заключить Договор
          с адресатом, который примет данное предложение.
        </p>
        <p>
          Совершение действий, указанных в настоящей Оферте, является подтверждением согласия обеих Сторон заключить
          Договор об оказании услуг на условиях, в порядке и объеме, изложенных в настоящей Оферте.
        </p>
        <p>
          Данный текст Публичной оферты является официальным публичным предложением ООО «PVT KEY», адресованным
          заинтересованным лицам, заключить Договор об оказании услуг в соответствии с пунктом 2 статьи 437 Гражданского
          кодекса РФ. Договор вступает в силу с момента совершения Сторонами действий, подтверждающих полное принятие
          всех условий Оферты без изъятий или ограничений.
        </p>

      <h3>Термины и определения</h3>
        <p><b>Договор</b> — текст настоящей Оферты с Приложениями, являющимися её неотъемлемой частью, акцептированный
          Заказчиком посредством совершения действий, предусмотренных Офертой.</p>
        <p><b>Сайт Исполнителя в сети Интернет</b> — совокупность программ и информации, доступ к которой осуществляется по адресу: https://pvtkey.ru/</p>
        <p><b>Стороны</b> — Исполнитель и Заказчик.</p>
        <p><b>Услуга</b> — услуга, оказываемая Исполнителем Заказчику на условиях, установленных настоящей Офертой.</p>

      <h3>Предмет Договора</h3>
        <p>2.1. ООО «PVT KEY» обязуется оказать Заказчику Услуги, а Заказчик обязуется оплатить их в размере, порядке и сроках, установленных настоящим Договором.</p>
        <p>2.2. Условия оказания Услуг определяются на основании данных Исполнителя при оформлении заявки Заказчиком, либо указаны на сайте Исполнителя: https://pvtkey.ru/.</p>
        <p>2.3. Договор заключается посредством акцепта настоящей Оферты, что подтверждается выполнением Заказчиком действий: регистрации на Сайте, подачи заявки и/или оплаты Услуг.</p>

      <h3>Права и обязанности Сторон</h3>
        <ul>
          <li><b>3.1. Исполнитель обязуется:</b></li>
          <ul>
            <li>Оказать Услуги в соответствии с положениями Договора.</li>
            <li>Обеспечить конфиденциальность данных Заказчика.</li>
            <li>Публиковать изменения условий Оферты на Сайте.</li>
          </ul>
          <li><b>3.2. Заказчик обязуется:</b></li>
          <ul>
            <li>Предоставить достоверные данные.</li>
            <li>Использовать информацию и материалы только в личных целях.</li>
          </ul>
        </ul>

      <h3>Цена и порядок расчетов</h3>
        <p>4.1. Стоимость услуг и порядок их оплаты устанавливаются на сайте Исполнителя.</p>

      <h3>Конфиденциальность и безопасность</h3>
        <p>Стороны обязуются обеспечивать конфиденциальность информации и персональных данных Заказчика в соответствии с ФЗ «О персональных данных».</p>

      <h3>Форс-мажор</h3>
        <p>Стороны освобождаются от ответственности за неисполнение обязательств по Договору при наступлении обстоятельств непреодолимой силы.</p>

      <h3>Ответственность Сторон</h3>
        <p>В случае неисполнения обязательств по Договору ответственность устанавливается в соответствии с условиями Оферты и действующим законодательством РФ.</p>

      <h3>Срок действия Оферты</h3>
        <p>Оферта вступает в силу с момента размещения на Сайте Исполнителя и действует до её отзыва. Исполнитель вправе изменять условия Оферты в одностороннем порядке.</p>

      <h3>Дополнительные условия</h3>
        <p>Договор регулируется законодательством РФ. В случае спора Стороны обязуются урегулировать его мирным путём.</p>

      <h3>Реквизиты Исполнителя</h3>
        <p><b>Полное наименование:</b> ООО «PVT KEY»</p>
        <p><b>Контактный e-mail:</b> mydownpixel@gmail.com</p>
        <p><b>Сайт:</b> https://pvtkey.ru/</p>
    </div>
  );
};

export default Offer;
