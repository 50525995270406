// /Home.js
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileSection from './components/ProfileSection';
import KeySection from './components/KeySection';
import ReferralSection from './components/ReferralSection';
import LoadingSpinner from './components/LoadingSpinner';
import { TelegramThemeContext } from './contexts/TelegramThemeContext';
import './Home.css';

function Home({ verificationFailed }) {
  const [loading, setLoading] = useState(true);
  const [responseMessage, setResponseMessage] = useState('');
  const theme = useContext(TelegramThemeContext);
  const navigate = useNavigate();

  const buttonOptions = [
    { price: 300, limit: 1209600, label: '300 RUB - 2 недели' },
    { price: 500, limit: 2629743, label: '500 RUB - 1 месяц' },
    { price: 1200, limit: 7889229, label: '1200 RUB - 3 месяца' },
    { price: 2000, limit: 15778458, label: '2000 RUB - 6 месяцев' },
    { price: 3500, limit: 31556916, label: '3500 RUB - 12 месяцев' },
  ];

  const [selectedButton, setSelectedButton] = useState(buttonOptions[2]);

  const rawMarzbanUserData = sessionStorage.getItem('marzbanUserData');
  const marzbanUserData = rawMarzbanUserData ? JSON.parse(rawMarzbanUserData) : null;

  const storedUserData = sessionStorage.getItem('userData');
  const userData = storedUserData ? JSON.parse(storedUserData) : null;

  const profilePictureUrl = '/logo.jpg';

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    const tg = window.Telegram.WebApp;

    const handleNavigateTariffs = () => {
      if (userData?.id) {
        navigate(`/tariffs?userId=${userData.id}`);
      } else {
        setResponseMessage('User ID is missing.');
      }
    };

    // Set up MainButton and show it
    tg.MainButton.setText("Продлить ключ").show().onClick(handleNavigateTariffs);

    // Clean up the MainButton on component unmount
    return () => {
      tg.MainButton.offClick(handleNavigateTariffs).hide();
    };
  }, [userData, navigate]);

  const handleButtonClick = (price, limit) => {
    setSelectedButton({ price, limit });
  };

  const handleNavigateToBot = () => {
    window.Telegram.WebApp.openLink(`https://t.me/${process.env.REACT_APP_BOT_NAME}`); //
  };

  const handleNavigateToChannel = () => {
    window.Telegram.WebApp.openLink(`https://t.me/pvtkey`);
  };

  const handleNavigateToSupport = () => {
    window.Telegram.WebApp.openLink(`https://t.me/pvt_key`);
  };

  const handleNavigateKeyInfo = () => {
    navigate('/key-info');
  };

  const handleNavigateReferralInfo = () => {
    navigate('/referral-info');
  };

  const handleNavigateOffer = () => {
    navigate('/offer');
  };

  const handleNavigatePrivacy = () => { //
    navigate('/privacy');
  };

  if (verificationFailed) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="wrapper" style={{ width: '100%', maxWidth: '400px' }}>
          <h1>PVT key</h1>
          <div>Ключи от интернета</div>
          <div className="button-group column" style={{ textAlign: 'center', marginBottom: '30px' }}>
            {buttonOptions.map(({ price, limit, label }) => (
              <button
                key={price}
                className={`traffic-button ${selectedButton?.price === price ? 'active' : ''}`}
                onClick={() => handleButtonClick(price, limit)}
              >
                {label}
              </button>
            ))}
          </div>
          <button
            onClick={() => window.location.href = `https://t.me/${process.env.REACT_APP_BOT_NAME}`}
            style={{
              backgroundColor: '#0088cc',
              border: 'none',
              borderRadius: '10px',
              color: 'white',
              padding: '10px 20px',
              cursor: 'pointer',
            }}
          >
            Начать пользоваться
          </button>

          {/*<button
            style={{
              marginTop: '20px',
              border: 'none',
              background: 'none',
              color: '#0088cc',
              cursor: 'pointer',
            }}
            onClick={handleNavigatePrivacy}
          >
            Политика конфиденциальности
          </button>*/}

          <button
            style={{
              marginTop: '10px',
              border: 'none',
              background: 'none',
              color: '#0088cc',
              cursor: 'pointer',
            }}
            onClick={handleNavigateOffer}
          >
            Публичная оферта
          </button>
        </div>
      </div>
    );
  }
  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="wrapper">
          <ProfileSection
            profilePictureUrl={profilePictureUrl} // Use logo.jpg for profile picture
            userData={userData}
            handleNavigateToBot={handleNavigateToBot}
            handleNavigateToChannel={handleNavigateToChannel}
            handleNavigateToSupport={handleNavigateToSupport}
          />

          <KeySection marzbanUserData={marzbanUserData} userData={userData} handleNavigateKeyInfo={handleNavigateKeyInfo} />

          <ReferralSection userData={userData} handleNavigateReferralInfo={handleNavigateReferralInfo} />

          {/* Display response message if there's an error */}
          {responseMessage && (
            <div style={{ marginTop: '20px', color: 'green' }}>
              <p>{responseMessage}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Home;
