import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Privacy = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const storedUserData = sessionStorage.getItem('userData');
  const params = new URLSearchParams(location.search);
  const userId = params.get('userId');

  useEffect(() => {
    if (storedUserData) {
      const tg = window.Telegram.WebApp;

      const handleNavigateHome = () => {
        navigate('/tariffs?userId=' + userId);
      };

      // Set up MainButton and show it
      tg.MainButton.setText("Назад").show().onClick(handleNavigateHome);

      // Clean up the MainButton on component unmount
      return () => {
        tg.MainButton.offClick(handleNavigateHome).hide();
      };
    }
  }, [navigate, storedUserData]); // Ensure `navigate` and `storedUserData` are dependencies

  return (
    <div className="wrapper">
      <h1 style={{fontSize: '22px'}}>Политика конфиденциальности</h1>
      <div>Текст политики</div>
    </div>
  );
};

export default Privacy;
