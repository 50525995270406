import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useNavigate } from 'react-router-dom';
import './Tariffs.css';

function Tariffs() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const userId = params.get('userId');

  const [loading, setLoading] = useState(false);
  
  const buttonOptions = [
    { price: 300, limit: 1209600, label: '300 RUB - 2 недели' },
    { price: 500, limit: 2629743, label: '500 RUB - 1 месяц' },
    { price: 1200, limit: 7889229, label: '1200 RUB - 3 месяца' },
    { price: 2000, limit: 15778458, label: '2000 RUB - 6 месяцев' },
    { price: 3500, limit: 31556916, label: '3500 RUB - 12 месяцев' },
  ];

  const [selectedButton, setSelectedButton] = useState(buttonOptions[2]);

  const handleButtonClick = (price, limit) => {
    setSelectedButton({ price, limit });
  };

  const handlePayment = async () => {
    const invoiceId = uuidv4();

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/initialize-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          amount: selectedButton.price,
          description: selectedButton.limit,
          invoiceId,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        if (window.Telegram.WebApp) {
          window.Telegram.WebApp.openLink(data.paymentData.pay_url);
          navigate(`/payment-result?invoiceId=${invoiceId}`);
        } else {
          window.open(data.paymentData.pay_url, '_blank');
          navigate(`/payment-result?invoiceId=${invoiceId}`);
        }
      } else {
        console.error('Failed to initialize payment:', data.error);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const tg = window.Telegram.WebApp;

    tg.MainButton.setText("Продлить ключ");
    tg.MainButton.show();
    tg.MainButton.onClick(handlePayment);

    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      navigate('/');
    });

    return () => {
      tg.MainButton.offClick(handlePayment).hide();
      tg.BackButton.offClick().hide();
    };
  }, [selectedButton]);

  const handleNavigateOffer = () => {
    navigate('/offer?userId=' + userId);
  };

  const handleNavigatePrivacy = () => { //
    navigate('/privacy?userId=' + userId);
  };

  return (
    <div className='wrapper'>
      <h1 className='text' style={{ fontSize: '22px', textAlign: 'center' }}>Выберите объем трафика</h1>
      
      <div className="button-group column" style={{ textAlign: 'center', marginBottom: '30px' }}>
        {buttonOptions.map(({ price, limit, label }) => (
          <button
            key={price}
            className={`traffic-button ${selectedButton?.price === price ? 'active' : ''}`}
            onClick={() => handleButtonClick(price, limit)}
          >
            {label}
          </button>
        ))}
      </div>

      <div className='description'>
        Нажимая на кнопку вы соглашаетесь с {/*<span style={{color: '#0088cc', cursor: 'pointer'}} onClick={handleNavigatePrivacy}>Политикой конфиденциальности</span> и*/}<span style={{color: '#0088cc', cursor: 'pointer'}} onClick={handleNavigateOffer}>Публичной офертой</span><br /><br />
        После оплаты лимит трафика обновится в личном кабинете автоматически. Если этого не произошло, перезагрузите мини-приложение.<br /><br />
      </div>
      
      {loading ? (
        <div style={{ marginTop: '20px' }} className='text'>
          <p>Секундочку...</p>
        </div>
      ) : null}
    </div>
  );
}

export default Tariffs;
