// /components/LoadingSpinner.js
import React from 'react';

function LoadingSpinner() {
  return (
    <div className='text' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <p>Загрузка...</p>
    </div>
  );
}

export default LoadingSpinner;
