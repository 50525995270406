import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Home from '../Home';
import Tariffs from '../Tariffs';
import PaymentResult from '../paymentResult';
import PaymentSuccess from '../paymentSuccess';
import PaymentFail from '../paymentFail';
import PaymentCancel from '../paymentCancel';
import InfoKey from '../InfoKey';
import InfoReferral from '../InfoReferral';
import Offer from '../Offer';
import Privacy from '../Privacy';

const getBasePath = () => {
  return process.env.NODE_ENV === 'development' ? '/development' : '';
};

const AnimatedRoutes = ({ verificationFailed }) => {
  const location = useLocation();
  const basePath = getBasePath();

  return (
    <AnimatePresence mode="wait">
      <div className={`content ${location.state?.drawerOpen ? 'drawer-open' : ''}`}>
        <Routes location={location} key={location.pathname}>
          <Route path={`${basePath}/`} element={<Home verificationFailed={verificationFailed} />} />
          <Route path={`${basePath}/tariffs`} element={<Tariffs />} />
          <Route path={`${basePath}/payment-result`} element={<PaymentResult />} />
          <Route path={`${basePath}/payment-success`} element={<PaymentSuccess />} />
          <Route path={`${basePath}/payment-fail`} element={<PaymentFail />} />
          <Route path={`${basePath}/payment-cancel`} element={<PaymentCancel />} />
          <Route path={`${basePath}/key-info`} element={<InfoKey />} />
          <Route path={`${basePath}/referral-info`} element={<InfoReferral />} />
          <Route path={`${basePath}/offer`} element={<Offer />} />
          <Route path={`${basePath}/privacy`} element={<Privacy />} />
        </Routes>
      </div>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
