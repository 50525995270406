import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { TelegramThemeContext } from '../contexts/TelegramThemeContext';

function KeySection({ marzbanUserData, handleNavigateKeyInfo }) {
  const [copyButtonText, setCopyButtonText] = useState('Скопировать ключ');
  const [statusColor, setStatusColor] = useState('🟢');
  const [statusText, setStatusText] = useState('Активный');
  const [formattedDate, setFormattedDate] = useState(null);
  const theme = useContext(TelegramThemeContext);


  // Copy key functionality with fallback
  const handleCopyKey = () => {
    if (navigator.clipboard && marzbanUserData?.links?.[0]) {
      navigator.clipboard.writeText(marzbanUserData.links[0])
        .then(() => {
          setCopyButtonText('Ключ скопирован');
          setTimeout(() => setCopyButtonText('Скопировать ключ'), 2000);
        })
        .catch((err) => {
          console.error('Could not copy text using clipboard API:', err);
          // Fallback to execCommand
          const textArea = document.createElement('textarea');
          textArea.value = marzbanUserData.links[0];
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          try {
            document.execCommand('copy');
            setCopyButtonText('Ключ скопирован ✅');
            setTimeout(() => setCopyButtonText('Скопировать ключ'), 2000);
          } catch (err) {
            console.error('Fallback copy method failed:', err);
          }
          document.body.removeChild(textArea);
        });
    } else {
      alert('Clipboard API not available or link is missing.');
    }
  };

  // Calculate traffic percentage and set status color and text based on usage
  /*const calculatePercentage = () => {
    return marzbanUserData.expire > 0
      ? marzbanUserData.used_traffic / marzbanUserData.data_limit
      : 0;
  };

  useEffect(() => {
    if (marzbanUserData) {
      const percentage = calculatePercentage();

      // Set status color and text based on traffic usage
      if (percentage > 0.99) {
        setStatusColor('🔴');
        setStatusText('Пополните баланс');
      } else if (percentage > 0.9) {
        setStatusColor('🟠');
        setStatusText('Активный');
      } else {
        setStatusColor('🟢');
        setStatusText('Активный');
      }

      console.log(marzbanUserData);
    }
  }, [marzbanUserData]);*/

  const formatTime = (utcTimestampSec) => {

    // Convert seconds to milliseconds
    const date = new Date(utcTimestampSec * 1000);

    // Format the date to Russian format
    const formattedDate = new Intl.DateTimeFormat('ru-RU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'Europe/Moscow'
    }).format(date);

    return formattedDate;
  };

  useEffect(() => {
    if (marzbanUserData) {

      const currentDate = Math.floor(Date.now() / 1000);
      const expireDate = marzbanUserData.expire;
      console.log('this date, expire date', currentDate, expireDate);

      if (marzbanUserData.expire != null) {
        const formattedDate = formatTime(marzbanUserData.expire);
        setFormattedDate(formattedDate);
      } else {
        setFormattedDate('никогда');
      }
      
      // Set status color and text based on traffic usage
      /*if (percentage > 0.99) {
        setStatusColor('🔴');
        setStatusText('Пополните баланс');
      } else if (percentage > 0.9) {
        setStatusColor('🟠');
        setStatusText('Активный');
      } else {
        setStatusColor('🟢');
        setStatusText('Активный');
      }*/

      console.log(marzbanUserData);

      if (expireDate < currentDate) {
        setStatusColor('🔴');
        setStatusText('Пополните баланс');
      } else if (expireDate <= currentDate + 86400) {
        setStatusColor('🟠');
        setStatusText('Ключ скоро истечет');
      } else {
        setStatusColor('🟢');
        setStatusText('Активный');
      }
    }
  }, [marzbanUserData]);

  // Interpolating color for status bar between green and red
  /*const interpolateColor = (percent, startColor, endColor) => {
    const start = {
      r: parseInt(startColor.slice(1, 3), 16),
      g: parseInt(startColor.slice(3, 5), 16),
      b: parseInt(startColor.slice(5, 7), 16),
    };
    const end = {
      r: parseInt(endColor.slice(1, 3), 16),
      g: parseInt(endColor.slice(3, 5), 16),
      b: parseInt(endColor.slice(5, 7), 16),
    };

    const r = Math.round(start.r + (end.r - start.r) * percent);
    const g = Math.round(start.g + (end.g - start.g) * percent);
    const b = Math.round(start.b + (end.b - start.b) * percent);

    return `rgb(${r}, ${g}, ${b})`;
  };

  const statusBarColor = interpolateColor(calculatePercentage(), '#00FF00', '#FF0000');*/

  return (
    <div>
      <h2 className="text">Ключ</h2>
      <div className="section text secondary-bg-color" style={{ background: theme.secondary_bg_color }}>
        <div className="horizontal-flex">
          <div>
            <div className="status">
              <span>{statusColor}</span>{' '}
              <span
                style={{
                  color:
                    statusColor === '🔴'
                      ? 'red'
                      : statusColor === '🟠'
                        ? 'orange'
                        : 'rgb(20,160,5)',
                }}
              >
                {statusText}
              </span>
            </div>
            {/*<div className="status-bar-container" style={{ borderRadius: '10px' }}>
              <div
                className="status-bar"
                style={{
                  width: `${calculatePercentage() * 100}%`,
                  backgroundColor: statusBarColor,
                  borderRadius: '10px',
                  height: '20px',
                }}
              ></div>
              </div>*/}
            <div className="stats text">
              Ключ истекает:{' '}<br />
              <span>
                {formattedDate} (МСК)
              </span>
            </div>
          </div>
          <button style={{textDecoration: 'none', background: 'none', border: 'none'}} className='text-button-color' onClick={handleNavigateKeyInfo}>
            <span style={{fontSize: '34px'}}>📲 </span><span style={{fontWeight: 'bold', fontSize: '16px'}}>Инструкция</span>
          </button>
        </div>
        <button className="action-button" onClick={handleCopyKey}>
          <div className="copy-button-text">{copyButtonText}</div>
        </button>
      </div>
    </div>
  );
}

export default KeySection;