// /components/ReferralSection.js
import React, { useContext } from 'react';
import { TelegramThemeContext } from '../contexts/TelegramThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

function ReferralSection({ userData, handleNavigateReferralInfo }) {
    const theme = useContext(TelegramThemeContext);

    const handleShareClick = () => {
        const referralLink = `https://t.me/${process.env.REACT_APP_BOT_NAME}/${process.env.REACT_APP_APP_NAME}?startapp=${userData.tgid}`;

        if (navigator.share) {
          navigator.share({
            title: 'Поделиться ботом PVT Key',
            url: referralLink,
          })
          .catch((error) => {
            console.error('Error sharing:', error);
            });
        } else {
          // Fallback for browsers that do not support `navigator.share`
          const textArea = document.createElement('textarea');
          textArea.value = referralLink;
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          try {
            document.execCommand('copy');
            alert('Ссылка скопирована');
          } catch (err) {
            console.error('Fallback: Could not copy referral link:', err);
          }
          document.body.removeChild(textArea);
        }
    };

  return (
    <div>
      <h2 className='text'>Реферальная программа</h2>
      <div className='referral-description text'>
        Поделитесь своей реферальной ссылкой и получайте кэшбек 20% за все платежи ваших рефералов.
      </div>
      <div className='section text secondary-bg-color' style={{background: theme.secondary_bg_color }}>
        <div className='horizontal-flex'>
          <div>
            <div className='stats text'>Рефералов: {userData?.referral_count}</div>
            <div className='stats text'>Заработано: {userData?.referral_balance}</div>
          </div>
          <button className="icon-button" onClick={handleNavigateReferralInfo}>
            <FontAwesomeIcon icon={faQuestion} />
          </button>
        </div>
        <button className='action-button' onClick={handleShareClick}>
          <div className='share-button'>Поделиться ботом</div>
        </button>
      </div>
    </div>
  );
}

export default ReferralSection;
